body {
  background: black;
  margin: 0;
  padding: 0;
  font-family: "europa", sans-serif;
}

a {
  text-decoration: none;
}
p {
  margin: 0;
}

a:link {
  color: inherit;
}

/* visited link */
a:visited {
  color: inherit;
}

/* mouse over link */
a:hover {
  color: inherit;
}

/* selected link */
a:active {
  color: inherit;
}

button {
  appearance: none;
  background: inherit;
  border: inherit;
  outline: inherit;
  font-family: inherit;
}

input {
  font-family: inherit;
  outline: inherit;
  appearance: none;
  background: inherit;
  border: inherit;
}
